import { Button } from '@mantine/core'
import { Fragment } from 'react'
import { Flex } from '@components/flex'

const REGISTER_FOR_DEMO_URL =
  process.env.NEXT_PUBLIC_DEMO_URL ?? 'https://get.nextstage.ai/request-demo-ws'

export const ScheduleADemoCTAButton = () => {
  return (
    <Fragment>
      <Flex direction="row" data-testid="homepage-page-demo--registration">
        <Button
          size="md"
          color="blue"
          component="a"
          href={REGISTER_FOR_DEMO_URL}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ fontWeight: 'normal' }}
        >
          Schedule a demo
        </Button>
      </Flex>
    </Fragment>
  )
}
